// EXEMPT
/* eslint-disable i18next/no-literal-string */
import React from "react";
import { css } from "goober";

import Chart from "@/shared/Chart.jsx";
import { AreaWithGradient, Line } from "@/shared/ComponentsForChart.jsx";
import { PerfGrid } from "@/shared/Performance.style.jsx";
import { PerformanceBars } from "@/shared/PerformanceBars.jsx";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import { chartLine } from "@/shared/PerformanceCard.style.jsx";
import PerformanceInputStats from "@/shared/PerformanceInputStats.jsx";

export default function Performance() {
  const dummyData = Array.from({ length: 20 }).map((_, i) => ({
    xData: i,
    yData: Math.round(Math.random()) + Math.log(i + 1) * 2,
  }));
  const benchValue = 8;
  const benchmarkData = Array.from({ length: 20 }).map((_, i) => ({
    xData: i,
    yData: benchValue,
  }));
  return (
    <PerfGrid
      className="w-full"
      style={{
        "--performance-color": "var(--turq)",
        maxWidth: "var(--sp-container)",
      }}
    >
      <PerformanceCard
        title={["", "Damage per minute"]}
        score={0.42}
        subContent={
          <PerformanceBars
            rows={[
              {
                valueDisplay: "42",
                fill: 0.42,
                label: "You",
                img: "https://www.mobafire.com/images/champion/square/twitch.png",
                colorHsl: "var(--red-hsl)",
              },
              {
                valueDisplay: "60",
                fill: 0.6,
                label: "Your Avg",
              },
            ]}
          />
        }
        expandedContent={
          <div className="dummy-expanded" style={{ height: 100 }}>
            expanded content
          </div>
        }
        bgImg={{
          src: "https://www.tapeciarnia.pl/tapety/normalne/224781_twitch_new_splash_art_2014_league_of_legends.jpg",
        }}
      />
      <PerformanceCard
        title={["", "Kill participation"]}
        score={0.7}
        subContent={
          <PerformanceBars
            rows={[
              {
                valueDisplay: "70",
                fill: 0.7,
                label: "You",
                img: "https://www.mobafire.com/images/champion/square/twitch.png",
                colorHsl: "var(--green-hsl)",
              },
              {
                valueDisplay: "42",
                fill: 0.42,
                label: "Your Avg",
              },
            ]}
          />
        }
        expandedContent={
          <div className="dummy-expanded" style={{ height: 100 }}>
            expanded content
          </div>
        }
        bgImg={{
          src: "https://www.tapeciarnia.pl/tapety/normalne/224781_twitch_new_splash_art_2014_league_of_legends.jpg",
        }}
      />
      <PerformanceCard
        title={["", "Damage per minute"]}
        score={0.8}
        expandedContent={
          <PerformanceInputStats
            rows={[
              {
                label: ["", "Good Statistic"],
                valueDisplay: "99",
                fill: 0.99,
                color: "var(--green)",
              },
              {
                label: ["", "Neutral Statistic"],
                valueDisplay: "66",
                fill: 0.66,
                color: "var(--perf-neutral)",
              },
              {
                label: ["", "Low Statistic"],
                valueDisplay: "33",
                fill: 0.33,
                color: "var(--red)",
              },
            ]}
          />
        }
        bgImg={{
          src: "https://www.tapeciarnia.pl/tapety/normalne/224781_twitch_new_splash_art_2014_league_of_legends.jpg",
        }}
        subContent={
          <>
            <div className="pad-2-0 type-callout--semi shade2 flex justify-between">
              <div>Damage/min</div>
              <div className="flex gap-sp-2">
                <div
                  className={`flex align-center gap-sp-1 ${css`
                    &::before {
                      content: "";
                      display: block;
                      width: 8px;
                      height: 4px;
                      border-radius: 2px;
                      background: var(--green);
                    }
                  `}`}
                >
                  This Game
                </div>
                <div
                  className={`flex align-center gap-sp-1 ${css`
                    &::before {
                      content: "";
                      display: block;
                      width: 8px;
                      height: 4px;
                      border-radius: 2px;
                      background: var(--shade1);
                    }
                  `}`}
                >
                  Avg
                </div>
              </div>
            </div>
            <Chart
              xField="xData"
              yField="yData"
              xAxisConf={{ show: false, type: "linear" }}
              yAxisConf={{
                show: true,
                type: "linear",
                orientation: "right",
                tickCount: 3,
                tickValues: [0, 4, 8],
                color: "var(--shade1)",
              }}
              data={dummyData}
              height={120}
              margin={{
                top: 0,
                right: 10,
                bottom: 0,
                left: 0,
              }}
            >
              <Line
                className={chartLine("var(--shade1)")}
                data={dummyData}
                xScale={undefined}
                yScale={undefined}
                xField={undefined}
                yField={undefined}
                clipPathId={undefined}
              />
              <AreaWithGradient
                gradientId="chart-data"
                data={dummyData}
                color="var(--shade1)"
                xScale={undefined}
                yScale={undefined}
                xField={undefined}
                yField={undefined}
                className={undefined}
                clipPathId={undefined}
              />

              <Line
                className={chartLine("var(--green)")}
                data={benchmarkData}
                xScale={undefined}
                yScale={undefined}
                xField={undefined}
                yField={undefined}
                clipPathId={undefined}
              />
              <AreaWithGradient
                gradientId="chart-bench"
                data={benchmarkData}
                color="var(--green)"
                xScale={undefined}
                yScale={undefined}
                xField={undefined}
                yField={undefined}
                className={undefined}
                clipPathId={undefined}
              />
            </Chart>
          </>
        }
      />
    </PerfGrid>
  );
}
